//@ts-ignore
// import QRCode from "qrcode.react";
// import { v4 as uuid } from "uuid";
//@ts-ignore
import React, { useState } from "react";
import { useEffect } from "react";
import { PhotoProgress } from "./types";
import { Uploader } from "./utils/Uploader";
//@ts-ignore
// import generate from "firebase-auto-ids";

import "@firebase/functions";
import "@firebase/firestore";
// import { use100vh } from "react-div-100vh";

interface Props {
  showLoader: boolean;
  result: any;
  onImageSelected: (file: File) => void;
  //   onImageSelected
}

const setHTML = (selector: string, value: string) => {
  const el = document.getElementById(selector);

  if (el) {
    if (el instanceof HTMLInputElement) {
      el.value = value ?? "";
    } else {
      el.innerHTML = value ? value : "&nbsp;";
    }
    if (value) {
      el.classList.remove("not-filled");
      el.classList.add("filled-ok");
    } else {
      el.classList.remove("filled-ok");
      el.classList.add("not-filled");
    }
  }
};

export const Kooperativa = () => {
  const [items, setItems] = useState<PhotoProgress[]>([]);

  useEffect(() => {
    const o = items
      .map((i) => i.result?.data?.data?.pages[0]?.gathered_data.fields)
      .filter((o) => o);
    const r = Object.assign({}, ...o);
    console.log("PPPP", r);
    //@ts-ignore
    window.RRR = r;

    // const gd = result?.data?.data?.pages[0]?.gathered_data;
    const mapicka = {
      "havarie:PanelVozidlo_POJISTENY:inside:PanelVozidlo_POJISTENY:VOZIDLO_TOVARNI_ZNACKA:inpVOZIDLO_TOVARNI_ZNACKA_label":
        r.brand_typ_variant_version?.texts?.[0]?.text,
      "havarie:PanelVozidlo_POJISTENY:inside:PanelVozidlo_POJISTENY:VOZIDLO_OBCHODNI_NAZEV:inpVOZIDLO_OBCHODNI_NAZEV":
        r.brand_model?.texts?.[0]?.text,
      "havarie:PanelVozidlo_POJISTENY:inside:PanelVozidlo_POJISTENY:VOZIDLO_VIN:inpVOZIDLO_VIN":
        r.vin?.texts?.[0]?.text,
      "havarie:PanelVozidlo_POJISTENY:inside:PanelVozidlo_POJISTENY:VOZIDLO_DRUH_VOZIDLA:inpVOZIDLO_DRUH_VOZIDLA":
        r.vehicle_type?.texts?.[0]?.text,

      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:PARTNER_JMENO:inpPARTNER_JMENO":
        // r.name_driver?.texts?.[0]?.text,
        r.name_driver?.entities?.find(
          (e: any) => e.type === "name_driver_parsed"
        )?.text,
      "vstupInfo:PanelVstupniInfo:VI_JMENO:inpVI_JMENO":
        r.name_driver?.entities?.find(
          (e: any) => e.type === "name_driver_parsed"
        )?.text,

      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:PARTNER_PRIJMENI:inpPARTNER_PRIJMENI":
        r.surname_driver?.texts?.[0]?.text,
      "vstupInfo:PanelVstupniInfo:VI_PRIJMENI:inpVI_PRIJMENI":
        r.surname_driver?.texts?.[0]?.text,
      "havarie:PanelVozidlo_POJISTENY:inside:PanelVozidlo_POJISTENY:VOZIDLO_RZ:inpVOZIDLO_RZ":
        r.registration_plate?.texts?.[0]?.text,
      "vstupInfo:PanelVstupniInfo:VI_PATTERN:inpVI_PATTERN":
        r.registration_plate?.texts?.[0]?.text,

      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:PARTNER_RC:inpPARTNER_RC":
        r.birth_number?.texts?.[0]?.text,

      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:adresaAutocomplete_input":
        r.residence?.texts?.[0]?.text,

      "havarie:PanelVozidlo_POJISTENY:inside:PanelVozidlo_POJISTENY:VOZIDLO_ROK_VYROBY:inpVOZIDLO_ROK_VYROBY":
        r.registration_date?.texts?.[0]?.text,

      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:obec:inputText":
        r.residence?.entities?.find(
          (r: any) => r.type === "residence_city_parsed"
        )?.text,

      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:castObce:inputText":
        r.residence?.entities?.find(
          (r: any) => r.type === "residence_city2_parsed"
        )?.text,
      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:psc:inputText":
        r.residence?.entities?.find(
          (r: any) => r.type === "residence_post_code_parsed"
        )?.text,
      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:ulice:inputText":
        r.residence?.entities?.find(
          (r: any) => r.type === "residence_address_raw_parsed"
        )?.text,
      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:cisloPopisne:inputText":
        r.residence?.entities?.find(
          (r: any) => r.type === "residence_land_registry_number_parsed"
        )?.text,
      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:cisloOrientacni:inputText":
        r.residence?.entities?.find(
          (r: any) => r.type === "residence_house_number_parsed"
        )?.text,

      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:PARTNER_RC:INPTITLE":
        // r.name_title_parsed?.texts?.[0]?.text,

        r.name_driver?.entities.find((e: any) => e.type === "name_title_parsed")
          ?.text,
    };

    //@ts-ignore
    Object.entries(mapicka).forEach(([key, val]) => setHTML(key, val));
  }, [items]);
  // console.log(docId);

  // if (!docId) {
  // return null;
  // }

  return (
    <>
      <Uploader onChange={setItems} classify={true} mine={true} />
    </>
  );
};
