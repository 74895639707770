import React, { useEffect, useState } from "react";

import { v4 as uuid } from "uuid";
// import logo from "./logo.svg";
// import "react-html5-camera-photo/build/css/index.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./App.css";
import "@firebase/functions";
import "@firebase/database";
import "@firebase/storage";

// import { use100vh } from "react-div-100vh";
import * as firebase from "firebase/app";
// import Webcam from "react-webcam";
// import { useMeasure } from "react-use";

// import ResizeObserverP from "resize-observer-polyfill";
import { Welcome } from "./Welcome";
import { Result } from "./Result";
import Page from "./Page";
import { trackEvent } from "./utils/tracking";
import { withSentry } from "./hoc/withSentry";
import * as Sentry from "@sentry/react";
import { Kooperativa } from "./Kooperativa";
// import { processFile } from "./functions";
import { Csob } from "./Csob";
import MobilePhoto from "./MobilePhoto";
import CarPrice from "./CarPrice";
import { processUploadedFile, uploadFile } from "./functions";
import { Uploader } from "./utils/Uploader";
import { Hav } from "./Hav";
import { HavVideo } from "./HavVideo";
import { Uniqa } from "./Uniqa";
import { Direct } from "./Direct";
import { MedicalReport } from "./MedicalReport";
// alert(ResizeObserverP);

// const el = document.documentElement;
// @ts-ignore
// el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);

document.getElementsByTagName("body")[0].addEventListener(
  "wheel",
  function (event) {
    // exception for ACE Editor, JS text editor used by sites like GitHub
    //@ts-ignore
    if (event.target.classList.contains("ace_content")) {
      return;
    }
    event.stopPropagation();
  },
  true
);

var firebaseConfig = {
  apiKey: "AIzaSyALCyYnF2nAQwtDBtCwLPQaqdffv-_vCdc",
  authDomain: "instaclaim-mining.firebaseapp.com",
  databaseURL: "https://instaclaim-mining.firebaseio.com",
  projectId: "instaclaim-mining",
  storageBucket: "instaclaim-mining.appspot.com",
  messagingSenderId: "614446897411",
  appId: "1:614446897411:web:4ca9c15252b51990097136",
};
// Initialize Firebas
firebase.initializeApp(firebaseConfig);

// @ts-ignore
window.M = firebase.database();

if (process.env.NODE_ENV === "development") {
  // firebase.functions().useFunctionsEmulator("http://127.0.0.1:5001");
}

// categorization/v1.0/ocr-mining" -H "accept: application/json" -H "Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnQiOiJ6ZWx2aWsiLCJpYXQiOjE1MTYyMzkwMjJ9.yPs9elMdAJEIWAstNaHTEy9QJNLq8tfB5TzcN1ZV6yo" -H "Content-Type: application/octet-stream""

// var saveData = (function () {
//   var a = document.createElement("a");
//   document.body.appendChild(a);
//   //@ts-ignore
//   a.style = "display: none";
//   //@ts-ignore
//   return function (blob, fileName) {
//       // var json = JSON.stringify(data),
//       //     blob = new Blob([json], {type: "octet/stream"}),
//           var url = window.URL.createObjectURL(blob);
//       a.href = url;
//       a.download = fileName;
//       a.click();
//       window.URL.revokeObjectURL(url);
//   };
// }());

// eslint-disable-next-line @typescript-eslint/no-unused-vars

function App() {
  // const [isShowVideo, setIsShowVideo] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  const [result, setResult] = useState<any>(null);
  // const [result, setResult] = useState<any>(TEST_RESULT);
  // const [imgsrc, setImgsrc] = useState("");

  // const webcamRef = useRef<Webcam>();
  // const imgRef = useRef<HTMLImageElement>(null);

  // const capture = React.useCallback(
  //   () => {
  //     const imageSrc = webcamRef.current.getScreenshot();
  //   },
  //   [webcamRef]
  // );

  // let showVideoTimeoutId: any = null;

  // function clearShowVideoTimeout() {
  //   if (showVideoTimeoutId) {
  //     clearTimeout(showVideoTimeoutId);
  //   }
  // }

  useEffect(() => {
    trackEvent("module open", {});
  }, []);

  async function onImageSelected(file: File) {
    try {
      setShowLoader(true);
      setResult(null);
      const id = uuid();
      console.log("MAM");
      const ref = await uploadFile(id, file, true);
      const r = await processUploadedFile(id, ref.fullPath);

      setResult(r);
    } catch (e) {
      Sentry.captureException(e);
      alert("Něco se nepovedlo");
      console.log(e);
    } finally {
      setShowLoader(false);
    }

    // reader.result;
    // });
    // reader.readAsDataURL(file);
  }
  // async function handleTakePhoto() {
  //   setIsShowVideo(false);
  //   const dataUri = webcamRef.current!.getScreenshot({
  //     width: 320,
  //     height: 240,
  //   })!;

  //   // var memoryImg = document.createElement("img");
  //   // memoryImg.src = dataUri;
  //   // var width = memoryImg.width;
  //   // var height = memoryImg.height;
  //   // console.log({ xx: dataUri.length });

  //   setImgsrc(dataUri!);

  //   clearShowVideoTimeout();
  //   showVideoTimeoutId = setTimeout(() => {
  //     setIsShowVideo(true);
  //   }, 900);

  //   // console.log("takePhoto");
  //   // // const image = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAFhAJ/wlseKgAAAABJRU5ErkJggg=="
  //   // const image = dataUri;
  //   // // imgRef.current!.src = image!;
  //   const rq = mineit({ image: dataUri });
  //   const r = await rq;
  //   // // console.log(r);
  //   setResult(JSON.stringify(r));
  //   // console.log(JSON.stringify(r));
  //   // alert(JSON.stringify(r));
  // }

  // const img = useRef<any>()
  // console.log("Xx");
  // debugger;
  // const vhheight = use100vh();
  // const vhheight = window.screen.availHeight;
  // const [ref, { width, height }] = useMeasure();
  // var vwidth = width;
  // var vheight = height;
  // if (width < height) {
  // vwidth = height;
  // vheight = width;
  // }

  // const videoConstraints = {
  //   facingMode: { ideal: "environment" },
  //   width: { ideal: 4096 },
  //   height: { ideal: 2160 },
  //   // height: { min: height },
  //   // width: vwidth,
  //   // height: vheight,
  //   // aspectRatio: width / height || 1,
  // };
  // const border = 4;
  // const tpr = 411 / 604;

  // var vh = height * 0.8;
  // var vw = vh / tpr;
  // if (width < height) {
  //   var vw = width * 0.8;
  //   var vh = vw * tpr;
  // }

  // const vt = (height - vh) / 2 - border;
  // const vl = (width - vw) / 2 - border;

  // var v = null;
  // console.log("Xxx");
  // con  sole.log(width);
  // if (width) {
  //   v = [
  //     <Webcam
  //       //@ts-ignore
  //       ref={webcamRef}
  //       forceScreenshotSourceSize={true}
  //       screenshotFormat={"image/png"}
  //       screenshotQuality={1}
  //       key="x"
  //       audio={false}
  //       videoConstraints={videoConstraints}
  //       width={width}
  //       height={height}
  //     ></Webcam>,
  //     <div
  //       style={{
  //         position: "absolute",
  //         top: 0,
  //         left: 0,
  //         bottom: 0,
  //         right: 0,
  //       }}
  //     >
  //       <div
  //         style={{
  //           position: "absolute",
  //           border: `${border}px solid #eee`,
  //           borderRadius: 10,
  //           width: vw,
  //           height: vh,
  //           left: vl,
  //           top: vt,
  //           boxShadow: "0 0 0 100vmax rgba(250,250, 250,0.6)",
  //         }}
  //       ></div>
  //     </div>,
  //     <WhiteFlash isShowWhiteFlash={!isShowVideo} />,
  //     <CircleButton isClicked={!isShowVideo} onClick={handleTakePhoto} />,
  //     ,
  //   ];
  //   // console.log(videoConstraints);
  // }

  // if (imgsrc) {
  //   // v = [<img src={imgsrc} width="300" onClick={() => setImgsrc("")}></img>];
  // }
  if (document.location.pathname === "/car-price") {
    return <CarPrice />;
  }

  if (document.location.pathname === "/medical-report/") {
    return (
      <div style={{ textAlign: "center" }}>
        <Page>
          <MedicalReport />
        </Page>
      </div>
    );
  }

  if (
    document.location.pathname === "/mobile-photo" &&
    document.location.hash
  ) {
    return <MobilePhoto id={document.location.hash.substr(1)} />;
    // document.location.replace(document.location + "/");
  }

  if (document.location.pathname === "/classification/") {
    // document.location.replace(document.location + "/");
    return (
      <div style={{ textAlign: "center" }}>
        <Uploader onChange={() => {}} classify={true} mine={false}></Uploader>
      </div>
    );
  }

  if (document.location.pathname === "/hav/") {
    return (
      <div style={{ textAlign: "center" }}>
        <Hav></Hav>
      </div>
    );
  }
  if (document.location.pathname === "/hav-video/") {
    return (
      <div style={{ textAlign: "center" }}>
        <HavVideo></HavVideo>
      </div>
    );
  }
  if (document.location.pathname === "/uniqa-havarijni") {
    document.location.replace(document.location + "/");
  }
  console.log(document.location.pathname);
  if (document.location.pathname === "/direct-sjednavac") {
    document.location.replace(document.location + "/");
  }

  if (document.location.pathname === "/kooperativa-skoda-havarie") {
    document.location.replace(document.location + "/");
  }
  if (document.location.pathname === "/csob-oznameni-skody-povinne-ruceni") {
    document.location.replace(document.location + "/");
  }

  if (document.location.pathname.match("/uniqa-havarijni/.*")) {
    return (
      <div style={{ textAlign: "center" }}>
        <Uniqa
        // onImageSelected={onImageSelected}
        // showLoader={showLoader}
        // result={result}
        />
      </div>
    );
  }
  if (document.location.pathname.match("/direct-sjednavac/.*")) {
    return (
      <div style={{ textAlign: "center" }}>
        <Direct
        // onImageSelected={onImageSelected}
        // showLoader={showLoader}
        // result={result}
        />
      </div>
    );
  }

  if (document.location.pathname.match("/kooperativa-skoda-havarie/.*")) {
    return (
      <div style={{ textAlign: "center" }}>
        <Kooperativa
        // onImageSelected={onImageSelected}
        // showLoader={showLoader}
        // result={result}
        />
      </div>
    );
  }

  if (
    document.location.pathname.match("/csob-oznameni-skody-povinne-ruceni/")
  ) {
    return (
      <div style={{ textAlign: "center" }}>
        <Csob
        // onImageSelected={onImageSelected}
        // showLoader={showLoader}
        // result={result}
        />
      </div>
    );
  }
  return (
    <>
      {result ? (
        <Page>
          <Result
            data={result}
            dismiss={() => {
              setResult(null);
              // setImgsrc("");
            }}
          />
        </Page>
      ) : (
        <Page>
          <Welcome onImage={onImageSelected} loading={showLoader} />
        </Page>
      )}
      {/* {showLoader && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader type="Rings" color="#00BFFF" height={100} width={100} />
        </div>
      )}
      <Logo
        style={{ alignSelf: "flex-start", paddingLeft: 20, height: 100 }}
        height={200}
      />
      {v}
      {/* <Camera
        idealFacingMode="environment"
        isImageMirror={false}
        onTakePhoto={(dataUri) => {
          handleTakePhoto(dataUri);
        }}
      /> */}
    </>
  );
}

export default withSentry(App);
