/* eslint-disable react-hooks/exhaustive-deps */
//@ts-ignore
import QRCode from "qrcode.react";
import { v4 as uuid } from "uuid";
import "@firebase/functions";
import "@firebase/firestore";
//@ts-ignore
import generate from "firebase-auto-ids";
import * as firebase from "firebase/app";

import React, { FC, useEffect, useState } from "react";
import {
  processClasification,
  processMedicalReport,
  processUploadedFile,
  uploadFile,
} from "../functions";
import { PhotoProgress } from "../types";
import {
  createTestFiles,
  createTestFilesAutaky,
  createTestFilesMedical,
} from "./fixtures";
import { WelcomeKoop } from "../WelcomeKoop";
import { Result } from "../Result";
import Loader from "../Loader";
// eslint-disable-next-line
import { TEST_ITEMS } from "./test-items";
import { createFile } from "./file";

const PhotoPreview: FC<{ info: PhotoProgress }> = ({ info }) => {
  const [preview, setPreview] = useState<string>();
  const [isPdf, setIsPdf] = useState(false);

  useEffect(() => {
    let objectUrl: string | null = null;
    // tohle je jenom kvuli  TEST_ITEMS, ktery nemaji File
    if (info.file.type === "application/pdf") {
      setPreview("/images/pdf-download-3388.png");
      return setIsPdf(true);
    }

    try {
      // create the preview
      objectUrl = URL.createObjectURL(info.file);
      setPreview(objectUrl);
    } catch {}
    // free memory when ever this component is unmounted
    return () => {
      objectUrl && URL.revokeObjectURL(objectUrl);
    };
  }, [info.file]);

  return (
    <div style={{ padding: 20, minWidth: 250 }}>
      <div
        style={{
          width: 250,
          height: 250,
          background: "black",
          borderRadius: 15,
        }}
      >
        <div
          onClick={async () => {
            // var file = new Blob([data], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(info.file);
            window.open(fileURL);
          }}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            objectFit: "cover",
            backgroundPosition: "center",
            backgroundImage: `url(${preview})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: isPdf ? undefined : 300,
            position: "relative",
            cursor: isPdf ? "pointer" : undefined,
          }}
        >
          {info.loading && <Loader color="#0bbbef"></Loader>}
        </div>
      </div>

      <div style={{ paddingTop: 10 }}>
        {!info.loading && info.result !== -1 && (
          <Result data={info.result} type={info.type}></Result>
        )}
      </div>
    </div>
  );
};
type Partial<T> = { [P in keyof T]?: T[P] };

export const Uploader: FC<{
  onChange: (items: PhotoProgress[]) => void;
  onNewFileRefPath?: (ref: string) => void;
  neukazujNic?: boolean;
  classify: boolean;
  showMobileQr?: boolean;
  mine: boolean;
  medical?: boolean;
  uploadToFirebase?: boolean;
  autaky?: boolean;
}> = ({
  onChange,
  neukazujNic = false,
  onNewFileRefPath,
  classify,
  mine,
  medical = false,
  showMobileQr = true,
  uploadToFirebase = true,
  autaky = false,
}) => {
  const [items, setItems] = useState<PhotoProgress[]>([]);
  const [loading, setLoading] = useState(false);
  // const [items, setItems] = useState<PhotoProgress[]>(
  //   TEST_ITEMS as PhotoProgress[]
  // );

  const compress = !medical;

  const [docId, setDocId] = useState("");

  useEffect(() => {
    // var m = document.location.pathname.match("/kooperativa-skoda-havarie/(.*)");
    // console.log("mammasd", m);
    if (!document.location.hash) {
      const id = generate(new Date());
      document.location.hash = "#" + id;
      setDocId(id);
    }
  });
  useEffect(() => {
    if (document.location.hash) {
      setDocId(document.location.hash.substr(1));
    }
  });

  console.log("2222222IIIIITTRTRTRTRTRT", items);
  const onNewPhotoUploaded = async (path: string) => {
    console.log("IIIIITTRTRTRTRTRT", items);
    const url = await firebase.storage().ref(path).getDownloadURL();
    console.log(url);
    const id = uuid();
    // zobrazim remote obrazek
    const file = await createFile(url);
    console.log("OOOOOOxxx", items);
    setItems((items) => [
      ...items,
      {
        file,
        id,
        loading: true,
        result: null,
      },
    ]);
    await makej(id, path);
  };
  useEffect(() => {
    if (!docId || neukazujNic) {
      return;
    }
    var registered = false;
    firebase
      .firestore()
      .collection("upload-sessions")
      .doc(docId)
      .collection("photos")
      .onSnapshot((doc) => {
        if (registered) {
          console.log("DDDDSASASAS", doc.docChanges()[0].doc.data());
          onNewPhotoUploaded(doc.docChanges()[0].doc.data().path);
        }
        registered = true;
      });
  }, [docId]);

  const loadSample = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    e.preventDefault();
    // await new Promise((r) => setTimeout(r, 2000));
    let files;
    if (medical) {
      files = await createTestFilesMedical();
    } else if (autaky) {
      files = await createTestFilesAutaky();
    } else {
      files = await createTestFiles();
    }

    setItems([]);
    await Promise.all(files.map(onPhotoSelect));
  };
  // useEffect(() => {
  //   loadSample();
  // }, []);

  useEffect(() => {
    onChange(items);
  }, [items, onChange]);

  const updateItem = async (
    id: string,
    partialItem: Partial<PhotoProgress>
  ) => {
    setItems((o) => {
      return o.map((p) => {
        if (p.id !== id) {
          return p;
        }
        return { ...p, ...partialItem };
      });
    });
  };

  const makej = async (id: string, path: string) => {
    const klas = async () => {
      const x = await processClasification(id, path);
      console.log("AASASAS22", x);
      var s = "";
      if (x?.data?.data?.pages?.[0]?.doc_type) {
        s = `${x?.data?.data?.pages?.[0]?.doc_type} (${Math.round(
          x?.data?.data?.pages?.[0]?.confidence * 100
        )}%)`;
      }

      updateItem(id, { type: s });
    };

    const ocr = async () => {
      const result = await processUploadedFile(id, path);
      console.log(result);
      updateItem(id, { result });
    };

    const medicalr = async () => {
      const result = await processMedicalReport(id, path);
      console.log(result);
      updateItem(id, { result });
    };

    await Promise.all([
      classify ? klas() : undefined,
      mine ? ocr() : undefined,
      medical ? medicalr() : undefined,
    ]);
    updateItem(id, { loading: false });
  };
  const onPhotoSelect = async (file: File) => {
    const id = uuid();
    const item: PhotoProgress = {
      id,
      file,
      loading: true,
      result: null,
    };
    setItems((o) => {
      return [...o, item];
    });

    if (uploadToFirebase) {
      const ref = await uploadFile(id, file, compress);
      try {
        await makej(id, ref.fullPath);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const onPhotoSelect2 = async (file: File) => {
    const id = uuid();
    const item: PhotoProgress = {
      id,
      file,
      loading: true,
      result: null,
    };

    setItems((o) => {
      return [...o, item];
    });

    if (uploadToFirebase) {
      const ref = await uploadFile(id, file, compress);
      onNewFileRefPath && onNewFileRefPath(ref.fullPath);
    }

    // try {
    //   await makej(id, ref.fullPath);
    // } catch (err) {
    //   console.error(err);
    // }
    // await makej(id, ref.fullPath);
    // console.log(ref.fullPath);
    // const result = await processFile(id, file);
    updateItem(id, { loading: false, result: -1 });
  };
  console.log("XXX", JSON.stringify(items));

  const mobilePhotoURL = new URL(document.location.href);
  mobilePhotoURL.pathname = "/mobile-photo";
  console.log(mobilePhotoURL.toString());
  // ?console.log(items);

  return (
    <>
      {!neukazujNic && !loading && !medical && (
        <button style={{ marginTop: 20 }} onClick={loadSample}>
          sample images
        </button>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            ...(neukazujNic
              ? { alignItems: "center", flexDirection: "column" }
              : {}),
          }}
        >
          {!items.length && loading && (
            <div style={{ height: 100, width: 100, position: "relative" }}>
              <Loader color="#0bbbef"></Loader>
            </div>
          )}
          {neukazujNic && <h1>Vyfoťte doklad</h1>}

          {items.map((item) => (
            <PhotoPreview info={item} key={item.id}></PhotoPreview>
          ))}
        </div>
        {neukazujNic ? (
          <WelcomeKoop onImage={onPhotoSelect2} loading={false} />
        ) : (
          <>
            {showMobileQr && (
              <div
                style={{
                  border: "4px solid rgba(238, 238, 238, 0.6)",
                  borderRadius: 4,
                  background: "white",
                  width: 400,
                  alignSelf: "center",
                  paddingBottom: 20,
                  marginTop: 10,
                }}
              >
                <h2>Pro focení na telefonu vyfoťte QR kód</h2>
                <QRCode value={mobilePhotoURL.toString()} />
              </div>
            )}

            <WelcomeKoop onImage={onPhotoSelect} loading={false} />
          </>
        )}
      </div>
    </>
  );
};
