import "@firebase/functions";
import "@firebase/storage";
// import { use100vh } from "react-div-100vh";

import * as firebase from "firebase/app";
import imageCompression from "browser-image-compression";
import { trackResult } from "./utils/tracking";

// export const processFile = async (id: string, file: File) => {
//   const functions = firebase.functions();
//   const mineit = functions.httpsCallable("technicakZadek", {
//     timeout: 5 * 60 * 1000,
//   });

//   const start = new Date();
//   const ref = await uploadFile(id, file);
//   // console.log("Uploaded a blob or file!");

//   // console.log(mountainsRef.fullPath);

//   // const dataUri = await imageCompression.getDataUrlFromFile(c);
//   const response = await mineit({ path: ref.fullPath });
//   const processingTime = new Date().valueOf() - start.valueOf();
//   trackResult(response, processingTime);
//   return response;
// };

export const processUploadedFile = async (id: string, path: string) => {
  const functions = firebase.functions();
  const mineit = functions.httpsCallable("technicakZadek", {
    timeout: 5 * 60 * 1000,
  });

  const start = new Date();

  // console.log("Uploaded a blob or file!");

  // console.log(mountainsRef.fullPath);

  // const dataUri = await imageCompression.getDataUrlFromFile(c);
  const response = await mineit({ path });
  const processingTime = new Date().valueOf() - start.valueOf();
  trackResult(response, processingTime);
  return response;
};

export const processClasification = async (id: string, path: string) => {
  const functions = firebase.functions();
  const mineit = functions.httpsCallable("klasifikace", {
    timeout: 5 * 60 * 1000,
  });

  const start = new Date();

  // console.log("Uploaded a blob or file!");

  // console.log(mountainsRef.fullPath);

  // const dataUri = await imageCompression.getDataUrlFromFile(c);
  const response = await mineit({ path });
  const processingTime = new Date().valueOf() - start.valueOf();
  trackResult(response, processingTime);
  return response;
};

export const processMedicalReport = async (id: string, path: string) => {
  const functions = firebase.functions();
  const mineit = functions.httpsCallable("medicalReportStaging", {
    timeout: 5 * 60 * 1000,
  });

  const start = new Date();

  // console.log("Uploaded a blob or file!");

  // console.log(mountainsRef.fullPath);

  // const dataUri = await imageCompression.getDataUrlFromFile(c);
  const response = await mineit({ path });
  const processingTime = new Date().valueOf() - start.valueOf();
  trackResult(response, processingTime);
  return response;
};

export const uploadFile = async (id: string, file: File, compress: boolean) => {
  console.log("TTT", file.type);
  var uploadDir = firebase.storage().ref("uploads");

  var ref = uploadDir.child(`${id}.png`);
  if (compress) {
    const c = await imageCompression(file, { maxSizeMB: 0.9 });
    await ref.put(c);
  } else {
    await ref.put(file);
  }
  return ref;
};

export const processCarPrice = async (data: any) => {
  const functions = firebase.functions();
  const mineit = functions.httpsCallable("carPricing", {
    timeout: 5 * 60 * 1000,
  });

  const start = new Date();

  // console.log("Uploaded a blob or file!");

  // console.log(mountainsRef.fullPath);

  // const dataUri = await imageCompression.getDataUrlFromFile(c);
  const response = await mineit(data);
  const processingTime = new Date().valueOf() - start.valueOf();
  trackResult(response, processingTime);
  return response;
};
